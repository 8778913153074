<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center" :headers="headers">
      <template #colgroup>
        <colgroup>
          <col class="w-32">
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
        </colgroup>
      </template>
      <template #item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template #item-Ritten="{ item }">
        <span>{{ item.totaal_aantal_ritten }}</span>
      </template>
      <template #item-Toegekend="{ item }">
        <span>{{ item.aantal_ritten_toegekend }}</span>
      </template>
      <template #item-Niet_Toegekend="{ item }">
        <span
          :class="{
            'font-bold text-red-500': item.totaal_aantal_ritten - item.aantal_ritten_toegekend > 0,
            'font-bold text-green-500': item.totaal_aantal_ritten - item.aantal_ritten_toegekend <= 0,
          }"
        >
          {{ item.totaal_aantal_ritten - item.aantal_ritten_toegekend }}
        </span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive'

import { monthToString } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = ['Datum', 'Ritten', 'Toegekend', 'Niet_Toegekend']

const { data } = useGetApi('/api/dashboard/stats/chauffeurs/toekenning', props.filters, { watch: true })
</script>
